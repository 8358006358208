global.jQuery = global.$ = window.jQuery = require('jquery');
import { TweenMax, TimelineMax } from "gsap";
loadPolyfills();
(function () {
	'use strict';
	$(function () {
		
		/*---------------------------------------------
		リンク消去
		---------------------------------------------*/
		$('.js-disable').click(function(){
			return false;
		});
		
	});
	
}).call(this);

const check = 'js loaded';


$(function () {
	
	$('body').css('opacity','1');
	const burger = $('#burger');
	const burgerSvg = {
		rect01:"#logoRect1",
		rect02:"#logoRect2",
		rect03:"#logoRect3",
		rect04:"#logoRect4",
		rect05:"#logoRect5",
	}
	const burgerSpeed = .3;
	const burgerEase = Power4.easeOut;
	const burgerAnim = new TimelineMax()
	.to(burgerSvg.rect01,burgerSpeed,{ease: burgerEase,autoAlpha:0})
	.to(burgerSvg.rect04,burgerSpeed,{ease: burgerEase,autoAlpha:0},"-=0.3")
	.to(burgerSvg.rect02,burgerSpeed,{ease: burgerEase,attr:{width:28,x:"2058",y:"37.5"}},"-=0.3")
	.to(burgerSvg.rect02,burgerSpeed,{ease: burgerEase,autoAlpha:0},"-=0.3")
	.to(burgerSvg.rect05,burgerSpeed,{ease: burgerEase,attr:{width:28,y:"37.5"}},"-=0.3")
	.to(burgerSvg.rect03,burgerSpeed,{ease: burgerEase,rotation:-45,transformOrigin:"50% 50%"},"-=0.3")
	.to(burgerSvg.rect05,burgerSpeed,{ease: burgerEase,rotation:45,transformOrigin:"50% 50%"},"-=0.2");
	
	burgerAnim.pause();



	let burgerFlag = false;
	const gnav = $('#jsNav');
	const gnavAbout = $('.p-header').find('.c-modal__link');
	
	let burgerShadow = "none";
	let height;
	let gnavList = gnav.find('.c-category__link');
	gnavAbout.removeClass('isOpen');
	TweenMax.set(gnavList, { x: 200, opacity: 0 });
	let bodyNoscroll = '';
	
	$(window).on('load resize',function(){
		let w = $(window).width();
		if(w > 768){
			burgerShadow = "rgba(16, 19, 21, 0.6) 0px 2px 8px 0px";
			bodyNoscroll = '';
		} else {
			bodyNoscroll = 'hidden';
		}
		height = $(window).innerHeight();
		gnav.css('height', height);
		gnav.find('.c-category').css('max-height', height * .7);
	});
	burger.each(function(){
		$(this).on('click',function(){
			if (!burgerFlag) {
				gnavAbout.addClass('isOpen');
				burgerAnim.play();
				burgerFlag = true;
				gnav.addClass('is-open');
				TweenMax.to(burger, .5, { boxShadow: "none" });
				$('body').css('overflow', bodyNoscroll);
				for (let i = 0; i < gnavList.length; i++){
					TweenMax.to(gnavList[i], .7, { x: 0, opacity: 1, delay: .15 * i, ease: burgerEase });
				}
			} else {
				gnavAbout.removeClass('isOpen');
				burgerAnim.reverse();
				burgerFlag = false;
				gnav.removeClass('is-open');
				$('body').css('overflow', '');
				TweenMax.to(burger, .8, { boxShadow: burgerShadow });
				for (let i = 0; i < gnavList.length; i++) {
					TweenMax.to(gnavList[i], 1.5, { x: 200, opacity: 0, delay: .05 * i, ease: burgerEase });
				}
			}
		});
	});


	// modal
	let modalflag = false;
	const modalOpen = $('.c-modal__link');
	const modal = $('.c-modal');
	modalOpen.each(function () {
		$(this).on('click', function () {
			if (!modalflag) {
				TweenMax.to(modal,1, { autoAlpha: 1,ease: burgerEase });
				modalflag = true;
				$('body').css('overflow', bodyNoscroll);
			} else {
				
			}
		});
	});
	function modalCheck() {
		if (modalflag) {
			TweenMax.to(modal, 1, { autoAlpha: 0,ease: burgerEase });
			modalflag = false;
			$('body').css('overflow', '');
		}	
	};
	
	$('.c-modal__btn').on('click', function () {
		modalCheck();
	});

	// $('.c-modal__btn'), on('click', function () {
	// 	modalCheck();
	// });



	// text count
	let titleText = $('.c-post__name');
	titleText.each(function () {
		let _self = $(this).text();
		let ttlCount = _self.length;
		let titleTextData;
		if ($(this).data('count')) {
			titleTextData = $(this).data('count');
		}
		if (ttlCount > 30) {
			let slicedTtl = _self.slice(0, 30) + "…";
			if (titleTextData) {
				slicedTtl = _self.slice(0, titleTextData) + "…";
			}
			$(this).html(slicedTtl);
		}
	});
	
	const acBtn = $('.p-townguide__btn');
	acBtn.each(function () {
		let acFlag = false;

		$(this).on('click', function () {
			const ac = $(this).parent();
			const acList = $(this).next();
			if (!acFlag) {
				ac.addClass('is-open');
				
				TweenMax.to(ac, .3, { marginBottom: '50px' });
				TweenMax.to(acList, .3, {
					height: 'auto',autoAlpha:1
				});
				acFlag = true;


			} else {
				ac.removeClass('is-open');
				// $(this).css('margin-bottom', '');
				TweenMax.to(ac, .3, {
					marginBottom: '0px'
				});
				TweenMax.to(acList, .3, {
					height: '0', autoAlpha: 0
				});
				acFlag = false;

			}
		});
	});
	
	
	$('.bnr-close').on('click',function(){
		TweenMax.to('.p-footer__float', 1 , { autoAlpha: 0,ease: burgerEase });
	});
	
	

	// slide
	
	var $activeSlide = $(".active"),
		$homeSlide = $(".p-slide__item"),
		$slideNavPrev = $(".slideNavPrev"),
		$slideNavNext = $(".slideNavNext"),
		$slideNavPrevA = $(".slideNavPrev a"),
		$slideNavNextA = $(".slideNavNext a"),
		title = '.p-slide__ttl__text',
		cat = '.p-slide__detail__upper__inner',
		slideObj = $('.p-slide__item');
	function init() {
		TweenMax.set($homeSlide.not($activeSlide), {
			autoAlpha: 0
		});
	}
	init();
	let slideFlag = true;
	$slideNavNext.click(function (e) {
		if (slideFlag) {
			slideFlag = false;
			clickEventNext(e);
		} else {
			return false;
		}

	});
	const slideSpeed = 0.6,
		  slideWrapSpeed = 0.8;
	function goToNextSlide(slideOut, slideIn, slideDone) {
	var tl = new TimelineMax({onComplete: finished}),
			slideOutH1 = slideOut.find(title),
			slideOutP = slideOut.find(cat),
			slideInH1 = slideIn.find(title),
			slideInP = slideIn.find(cat),
			index = slideIn.index(),
			size = $('.p-slide__wrapper .p-slide__item').length;
		if (slideIn.length !== 0) {
			if (slideOut.index() === (slideObj.length - 1)) {
				TweenMax.set(slideDone, {
					y: '-100%'
				});
				tl
					.set(slideIn, {
						y: '100%',
						autoAlpha: 1,
						className: '+=active'
					})
					.set(slideOut, {
						className: '-=active'
					})
					.to([slideOutH1], slideSpeed, {
						xPercent: 20,
						autoAlpha: 0,
						ease: Power3.easeInOut
					}, 0)
					.to([slideOutP], slideSpeed/2, {
						autoAlpha: 0,
						ease: Power3.easeInOut
					}, 0)
					.to(slideDone, slideWrapSpeed, {
						y: '-100%',
						ease: Power3.easeInOut
					}, 0)
					.to(slideOut, slideWrapSpeed, {
						y: '-100%',
						ease: Power3.easeInOut
					}, 0)
					.to(slideIn, slideWrapSpeed, {
						y: '-=100%',
						ease: Power3.easeInOut
					}, 0)
					.fromTo([slideInH1], slideSpeed, {
						y: '+=45px',
						autoAlpha: 0
					}, {
						autoAlpha: 1,
						y: 0,
						ease: Power1.easeInOut
					}, 0.3)
					.fromTo([slideInP], slideSpeed*3, {
						autoAlpha: 0
					}, {
						autoAlpha: 1,
						ease: Power1.easeInOut
					}, 0.5);
			} else {
				tl
					.set(slideIn, {
						y: '100%',
						autoAlpha: 1,
						className: '+=active'
					})
					.set(slideOut, {
						className: '-=active'
					})
					.to([slideOutH1], slideSpeed, {
						xPercent: 20,
						autoAlpha: 0,
						ease: Power3.easeInOut
					}, 0)
					.to([slideOutP], slideSpeed, {
						autoAlpha: 0,
						ease: Power3.easeInOut
					}, 0)
					.to(slideDone, slideWrapSpeed, {
						y: '-100%',
						ease: Power3.easeInOut
					}, 0)
					.to(slideIn, slideWrapSpeed, {
						y: '-=100%',
						ease: Power3.easeInOut
					}, 0)
					.fromTo([slideInH1], slideSpeed, {
						y: '+=45px',
						autoAlpha: 0
					}, {
						autoAlpha: 1,
						y: 0,
						ease: Power1.easeInOut
					}, 0.3)
					.fromTo([slideInP], slideSpeed * 3, {
						autoAlpha: 0
					}, {
						autoAlpha: 1,
						ease: Power1.easeInOut
					}, 0);
			}


		}
	}
	var slideDonePrev = slideObj.eq(slideObj.length - 2),
		slideInPrev = slideObj.eq(slideObj.length - 1);
	$slideNavPrev.click(function (e) {
		if (slideFlag) {
			slideFlag = false;
			clickEventPrev(e);

		} else {
			return false;
		}
	});


	function goToPreviousSlide(slideOut, slideIn, slideDone) {

		var tl = new TimelineMax({ onComplete: finished }),
			slideOutH1 = slideOut.find(title),
			slideOutP = slideOut.find(cat),
			slideInH1 = slideIn.find(title),
			slideInP = slideIn.find(cat),
			index = slideIn.index(),
			size = $('.p-slide__wrapper .p-slide__item').length;

		if (slideIn.length !== 0) {
		
			
			tl
				.set(slideIn, {
					y: '-100%',
					autoAlpha: 1,
					className: '+=active'
				})

				.set(slideOut, {
					className: '-=active'
				})

				.to([slideOutH1], 0.3, {
					y: '-=15px',
					autoAlpha: 0,
					ease: Power3.easeInOut
				}, 0)
				.to([slideOutP], 0.3, {
					autoAlpha: 0,
					ease: Power3.easeInOut
				}, 0)
				.to(slideOut, 0.5, {
					y: '100%',
					ease: Power3.easeInOut
				}, 0)
				.to(slideIn, 0.5, {
					y: '+=100%',
					autoAlpha: 1,
					ease: Power3.easeInOut
				}, '-=0.5')
				.fromTo([slideInH1], 0.3, {
					xPercent: 20,
					autoAlpha: 0
				}, {
					autoAlpha: 1,
					xPercent: 0,
					ease: Power1.easeInOut
				}, 0.3)
				.fromTo([slideInP], 0.3, {
					autoAlpha: 0
				}, {
					autoAlpha: 1,
					ease: Power1.easeInOut
				}, 0.3);
		}


	}
	// click event functions
	function clickEventNext(e) {
		e.preventDefault();

		var slideOut = $('.p-slide__item.active'),
			slideIn = $('.p-slide__item.active').next('.p-slide__item'),
			slideDone = $('.p-slide__item.active').prev('.p-slide__item');
		TweenMax.set(title, {
			xPercent: 0
		});
		if (slideOut.index() === (slideObj.length - 1)) {
			slideIn = slideObj.eq(0);
		}
		if (!slideDone) {
			slideDone = slideObj.eq(slideObj.length - 2);
		}

		goToNextSlide(slideOut, slideIn, slideDone);
	}
	function clickEventPrev(e) {
		e.preventDefault();
		var slideOut = $('.p-slide__item.active');
		if (slideOut.index() === (slideObj.length - 1)) {
		}
		slideInPrev = $('.p-slide__item.active').prev('.p-slide__item');
		slideDonePrev = $('.p-slide__item.active').prev('.p-slide__item').prev('.p-slide__item');
		TweenMax.set(title, {
			y: 0
		});
		if (slideOut.index() === 0) {
			slideInPrev = $('.p-slide__item:last-child');
			TweenMax.set(slideObj.eq(slideObj.length -1), {
				y: 0
			});
		}
		goToPreviousSlide(slideOut, slideInPrev, slideDonePrev);
	}
	// autoloop
	setInterval(function () {
		$slideNavNext.trigger('click');
		if (slideFlag) {
			slideFlag = false;
			

		} else {
			return false;
		}
	}, 8000)
	
	function finished() {
		slideFlag = true;
	}
	const viewmore = $('.p-main__more__btn');
	
	viewmore.each(function () {
		$(this).append('<span class="p-main__more__border"></span>')
		const moreBorder = $(this).find('.p-main__more__border');
		$(this).hover(function () {
			TweenMax.to(moreBorder, .3, {
				xPercent: 100,
				ease:Power4.easeOut
			});
		}, function () {
			TweenMax.to(moreBorder, .3, {
				xPercent: 0,
				ease:Power4.easeOut
			});
		});
	});

	const figcaption = $('figcaption');
	const li = $('li');
	li.each(function () {
		if ($(this).text() === '') {
			$(this).remove();
		}
	});
	figcaption.each(function () {
		if ($(this).text() === '') {
			$(this).remove();
		}
	});
	function townguideAc() {
		
	}	
	townguideAc();
	const acOptions = {
		root: null, 
		rootMargin: "-10% 0px -90%",
		threshold: 0 // 閾値は0
	};
	const acWrap = document.getElementById('townguideAc');
	const acTarget = document.getElementById('acTarget');
	const footer = document.getElementById('townguideBnr');
	let acFlag = false;
	const acObserver = new IntersectionObserver(
		function (entrires) {
			if (!acFlag && entrires[0].isIntersecting) {
				$('.p-townguide__content').addClass('isActive');

				acWrap.classList.add("isActive");
				acFlag = true;
				ftObserver.observe(footer);

			} else {
				$('.p-townguide__content').removeClass('isActive');
				acWrap.classList.remove("isActive");
				acFlag = false;
			}
		}
		, acOptions
	);
	if ($('#acTarget').length > 0) {
		acObserver.observe(acTarget);		
	}

	let ftFlag = false;
	const ftOptions = {
		root: null, 
		rootMargin: "0px",
		// rootMargin: "% 0px",
		threshold: 0
	};
	const ftObserver = new IntersectionObserver(
		function (entrires) {
			if (!ftFlag && entrires[0].isIntersecting) {
				// $('.p-townguide__content').removeClass('isActive');
				acWrap.classList.remove("isActive");
				acWrap.classList.add("isActiveBottom");
				acFlag = false;
				ftFlag = true;
				console.log('intersecting')
			} else {
				$('.p-townguide__content').addClass('isActive');
				acWrap.classList.remove("isActiveBottom");
				acWrap.classList.add("isActive");
				acFlag = true;
				ftFlag = false;
			}
		}
		, ftOptions
	);
});

jQuery(function($){
	var baseUrl = $('.siteurl-handler').text();

	// staging
	var accessJSON = baseUrl + '/wp-json/wp/v2/posts';

	if ($('.term-handler').length > 0) {
		var term = $('.term-handler').text();
		accessJSON += '?categories=' + term;
	}

	//全部で何件あるか
	var totalCount = 0;

	//何件ずつ表示するか
	var perPage = 8;

	//現在のページ
	var nowPage = 1;



	$('.ajax-tg__btn').on('click', function () {
		nowPage++
		getPost()
	});

	function getPost() {
		$.ajax({
			url: accessJSON,
			type: 'GET',
			data: {
				'page': nowPage,
				'per_page': perPage,
			}
		})
			.done(function (data, status, xhr) {
				//トータルの記事数を取得する
				totalCount = xhr.getResponseHeader('X-WP-Total')

				for (var i = 0; i < data.length; i++) {
					var target = data[i]

					//日付をformatする
					var date = new Date(target.date)
					var year = date.getFullYear()
					var month = date.getMonth() + 1

					if (String(month).length === 1) {
						month = '0' + String(month)
					}

					var day = date.getDate()
					if (String(day).length === 1) {
						day = '0' + String(day)
					}

					var dateString = year + '.' + month + '.' + day
					var category = target.category_name[0];
					var ucfirstCat = category.charAt(0).toUpperCase() + category.slice(1);
					var html = '<article class="c-post c-col_md6">'
					html += '<a href="' + target.link + '" class="c-post__link">'
					html += '<div class="c-post__thumb">'
					html += '<div class="c-post__thumb__inner">'
					html += '<figure class="c-post__img" style="background-image: url(\'' + target.acf['post-thumb'] + '\');  background-repeat: no-repeat; background-position: 50% 50%;">'
					html += '</figure>'
					html += '</div>'
					html += '</div>'
					html += '<div class="c-post__name">' + target.title.rendered + '</div>'
					html += '<div class="c-post__detail">'
					html += '<time class="c-post__date">' + dateString + '</time>'
					html += '<span class="c-post__cat">' + ucfirstCat  + '</span>'
					html += '</div>'
					html += '</a>'
					html += '</article>'
					$('.ajax-wrapper').append(html)
				}

				//すべて表示している場合は「もっと見るボタン」は表示しない
				if (totalCount < perPage * nowPage) {
					$('.ajax-tg').hide()
				}
			})
			.fail(function (data) {
				console.log(data)
			})
	}	
});


// Intersection Observer Polyfill
function loadPolyfills() {
	"use strict";

	var isIntersectionObserverSupported = "IntersectionObserver" in window;

	// Intersection Observer譛ｪ繧ｵ繝昴�繝域凾縺ｫPolyfill繧帝←逕ｨ
	if (!isIntersectionObserverSupported) {
		IntersectionObserverPolyfill();
	};

	/// minified version of the Intersection Observer polyfill from: https://github.com/w3c/IntersectionObserver/tree/master/polyfill
	function IntersectionObserverPolyfill() {
		(function (h, f) {
			function m(a) {
				this.time = a.time;
				this.target = a.target;
				this.rootBounds = a.rootBounds;
				this.boundingClientRect = a.boundingClientRect;
				this.intersectionRect = a.intersectionRect || l();
				this.isIntersecting = !!a.intersectionRect;
				a = this.boundingClientRect;
				a = a.width * a.height;
				var b = this.intersectionRect;
				b = b.width * b.height;
				this.intersectionRatio = a ? b / a : this.isIntersecting ? 1 : 0
			}

			function d(a, b) {
				var c = b || {};
				if ("function" != typeof a) throw Error("callback must be a function");
				if (c.root && 1 != c.root.nodeType) throw Error("root must be an Element");
				this._checkForIntersections = u(this._checkForIntersections.bind(this), this.THROTTLE_TIMEOUT);
				this._callback = a;
				this._observationTargets = [];
				this._queuedEntries = [];
				this._rootMarginValues = this._parseRootMargin(c.rootMargin);
				this.thresholds = this._initThresholds(c.threshold);
				this.root = c.root || null;
				this.rootMargin = this._rootMarginValues.map(function (a) {
					return a.value + a.unit
				}).join(" ")
			}

			function u(a, b) {
				var c = null;
				return function () {
					c || (c = setTimeout(function () {
						a();
						c = null
					}, b))
				}
			}

			function n(a, b, c, e) {
				"function" == typeof a.addEventListener ? a.addEventListener(b, c, e || !1) : "function" == typeof a.attachEvent && a.attachEvent("on" + b, c)
			}

			function r(a, b, c, e) {
				"function" == typeof a.removeEventListener ? a.removeEventListener(b, c, e || !1) : "function" == typeof a.detatchEvent && a.detatchEvent("on" + b, c)
			}

			function p(a) {
				try {
					var b = a.getBoundingClientRect()
				} catch (c) { }
				if (!b) return l();
				b.width && b.height || (b = {
					top: b.top,
					right: b.right,
					bottom: b.bottom,
					left: b.left,
					width: b.right - b.left,
					height: b.bottom - b.top
				});
				return b
			}

			function l() {
				return {
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
					width: 0,
					height: 0
				}
			}

			function t(a, b) {
				for (var c = b; c;) {
					if (c == a) return !0;
					c = q(c)
				}
				return !1
			}

			function q(a) {
				return (a = a.parentNode) && 11 == a.nodeType && a.host ? a.host : a
			}
			if ("IntersectionObserver" in h && "IntersectionObserverEntry" in h && "intersectionRatio" in h.IntersectionObserverEntry.prototype) "isIntersecting" in h.IntersectionObserverEntry.prototype || Object.defineProperty(h.IntersectionObserverEntry.prototype, "isIntersecting", {
				get: function () {
					return 0 < this.intersectionRatio
				}
			});
			else {
				var k = [];
				d.prototype.THROTTLE_TIMEOUT = 100;
				d.prototype.POLL_INTERVAL = null;
				d.prototype.USE_MUTATION_OBSERVER = !0;
				d.prototype.observe = function (a) {
					if (!this._observationTargets.some(function (b) {
						return b.element == a
					})) {
						if (!a || 1 != a.nodeType) throw Error("target must be an Element");
						this._registerInstance();
						this._observationTargets.push({
							element: a,
							entry: null
						});
						this._monitorIntersections();
						this._checkForIntersections()
					}
				};
				d.prototype.unobserve = function (a) {
					this._observationTargets = this._observationTargets.filter(function (b) {
						return b.element != a
					});
					this._observationTargets.length || (this._unmonitorIntersections(), this._unregisterInstance())
				};
				d.prototype.disconnect = function () {
					this._observationTargets = [];
					this._unmonitorIntersections();
					this._unregisterInstance()
				};
				d.prototype.takeRecords = function () {
					var a = this._queuedEntries.slice();
					this._queuedEntries = [];
					return a
				};
				d.prototype._initThresholds = function (a) {
					a = a || [0];
					Array.isArray(a) || (a = [a]);
					return a.sort().filter(function (a, c, e) {
						if ("number" != typeof a || isNaN(a) || 0 > a || 1 < a) throw Error("threshold must be a number between 0 and 1 inclusively");
						return a !== e[c - 1]
					})
				};
				d.prototype._parseRootMargin = function (a) {
					a = (a || "0px").split(/\s+/).map(function (a) {
						a = /^(-?\d*\.?\d+)(px|%)$/.exec(a);
						if (!a) throw Error("rootMargin must be specified in pixels or percent");
						return {
							value: parseFloat(a[1]),
							unit: a[2]
						}
					});
					a[1] = a[1] || a[0];
					a[2] = a[2] || a[0];
					a[3] = a[3] || a[1];
					return a
				};
				d.prototype._monitorIntersections = function () {
					this._monitoringIntersections || (this._monitoringIntersections = !0, this.POLL_INTERVAL ? this._monitoringInterval = setInterval(this._checkForIntersections, this.POLL_INTERVAL) : (n(h, "resize", this._checkForIntersections, !0), n(f, "scroll", this._checkForIntersections, !0), this.USE_MUTATION_OBSERVER && "MutationObserver" in h && (this._domObserver = new MutationObserver(this._checkForIntersections), this._domObserver.observe(f, {
						attributes: !0,
						childList: !0,
						characterData: !0,
						subtree: !0
					}))))
				};
				d.prototype._unmonitorIntersections = function () {
					this._monitoringIntersections && (this._monitoringIntersections = !1, clearInterval(this._monitoringInterval), this._monitoringInterval = null, r(h, "resize", this._checkForIntersections, !0), r(f, "scroll", this._checkForIntersections, !0), this._domObserver && (this._domObserver.disconnect(), this._domObserver = null))
				};
				d.prototype._checkForIntersections = function () {
					var a = this._rootIsInDom(),
						b = a ? this._getRootRect() : l();
					this._observationTargets.forEach(function (c) {
						var e = c.element,
							d = p(e),
							g = this._rootContainsTarget(e),
							f = c.entry,
							k = a && g && this._computeTargetAndRootIntersection(e, b);
						c = c.entry = new m({
							time: h.performance && performance.now && performance.now(),
							target: e,
							boundingClientRect: d,
							rootBounds: b,
							intersectionRect: k
						});
						f ? a && g ? this._hasCrossedThreshold(f, c) && this._queuedEntries.push(c) : f && f.isIntersecting && this._queuedEntries.push(c) : this._queuedEntries.push(c)
					}, this);
					this._queuedEntries.length && this._callback(this.takeRecords(), this)
				};
				d.prototype._computeTargetAndRootIntersection = function (a, b) {
					if ("none" != h.getComputedStyle(a).display) {
						for (var c = p(a), e = q(a), d = !1; !d;) {
							var g = null,
								k = 1 == e.nodeType ? h.getComputedStyle(e) : {};
							if ("none" == k.display) return;
							e == this.root || e == f ? (d = !0, g = b) : e != f.body && e != f.documentElement && "visible" != k.overflow && (g = p(e));
							if (g) {
								k = Math.max(g.top, c.top);
								var l = Math.min(g.bottom, c.bottom),
									m = Math.max(g.left, c.left);
								c = Math.min(g.right, c.right);
								g = c - m;
								var n = l - k;
								c = 0 <= g && 0 <= n && {
									top: k,
									bottom: l,
									left: m,
									right: c,
									width: g,
									height: n
								};
								if (!c) break
							}
							e = q(e)
						}
						return c
					}
				};
				d.prototype._getRootRect = function () {
					if (this.root) var a = p(this.root);
					else {
						a = f.documentElement;
						var b = f.body;
						a = {
							top: 0,
							left: 0,
							right: a.clientWidth || b.clientWidth,
							width: a.clientWidth || b.clientWidth,
							bottom: a.clientHeight || b.clientHeight,
							height: a.clientHeight || b.clientHeight
						}
					}
					return this._expandRectByRootMargin(a)
				};
				d.prototype._expandRectByRootMargin = function (a) {
					var b = this._rootMarginValues.map(function (b, d) {
						return "px" == b.unit ? b.value : b.value * (d % 2 ? a.width : a.height) / 100
					});
					b = {
						top: a.top - b[0],
						right: a.right + b[1],
						bottom: a.bottom + b[2],
						left: a.left - b[3]
					};
					b.width = b.right - b.left;
					b.height = b.bottom - b.top;
					return b
				};
				d.prototype._hasCrossedThreshold = function (a, b) {
					var c = a && a.isIntersecting ? a.intersectionRatio || 0 : -1,
						d = b.isIntersecting ? b.intersectionRatio || 0 : -1;
					if (c !== d)
						for (var f = 0; f < this.thresholds.length; f++) {
							var g = this.thresholds[f];
							if (g == c || g == d || g < c !== g < d) return !0
						}
				};
				d.prototype._rootIsInDom = function () {
					return !this.root || t(f, this.root)
				};
				d.prototype._rootContainsTarget = function (a) {
					return t(this.root || f, a)
				};
				d.prototype._registerInstance = function () {
					0 > k.indexOf(this) && k.push(this)
				};
				d.prototype._unregisterInstance = function () {
					var a = k.indexOf(this); - 1 != a && k.splice(a, 1)
				};
				h.IntersectionObserver = d;
				h.IntersectionObserverEntry = m
			}
		})(window, document);
	};

	// Mini polyfill for Array.from without optional arguments (mapFunction [second argument], thisArg [third argument]) (https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Array/from)
	if (typeof Array.from !== "function") {
		Array.from = function (arrLikeObj) {
			return Array.prototype.slice.call(arrLikeObj, 0);
		}
	};



};